:root {
  /* atlascopco  #2596be */
  /* atlascopco-border  rgb(5,148,188); */
  /* tecnotorq  #6d9ca6 */
  /* tecnotorq-border  rgb(112,178,192); */
  /* tecnotorq*/--custom-gradient2: linear-gradient(90deg, rgba(108,181,185,1) 0%, rgba(108,181,185,0.9473039215686274) 51%, rgba(120,231,215,0.9473039215686274) 100%);
  /* atlascopco*/--custom-gradient: linear-gradient(90deg, hsla(193, 95%, 38%, 1) 0%, hsla(210, 37%, 74%, 1) 100%);
  --custom-color: #2596be;
  --custom-border: rgb(5,148,188);
  --custom-hover: #63bbda;
  /* --custom-gradient */
  /* --custom-gradient */
}
  
/************ Custom Font ************/
@font-face {
	font-family: Centra;
	src: url('../assets/font/CentraNo2-Bold.ttf');
	font-weight: 700;
}
@font-face {
	font-family: Centra;
	src: url('../assets/font/CentraNo2-Medium.ttf');
	font-weight: 500;
}
@font-face {
	font-family: Centra;
	src: url('../assets/font/CentraNo2-Book.ttf');
	font-weight: 400;
}

/************ Default Css ************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container{
  max-width: 1538px !important;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #fff !important;
  color: #fff !important;
  font-family: 'Centra', sans-serif !important;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p, a, li, button, ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus, textarea:focus, select:focus {
  outline: none;
}

@media (min-width:1700px) {
	main .container {
		max-width: 100%;
		padding: 0 150px;
	}
}

p.success {
	color: green;
}

p.danger {
	color: red;
}

.App{
  display: flex;
  flex-direction: column;
  height: 100vh;
}

/* .content-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1; 
  overflow-y: auto;
} */

/************ Navbar Css ************/
nav.navbar {
  padding: 10px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition:  0.32s ease-in-out;
  background-color: var(--custom-color);
  box-shadow: -1px 7px 5px 0px rgb(234 239 236);
}
nav.navbar.scrolled {
  padding: 5px 0;
  background-color: var(--custom-color);
}
nav.navbar.scrolled .nav-link.navbar-link {
  color: white !important;
}

#link-connect{
  margin-left: 15px;
}
nav.navbar a.navbar-brand {
  width: 11%;
}
.image-logo {
  border-radius: 11px;
}
nav.navbar .navbar-nav .nav-link.navbar-link {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 400;
  color: white !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
}

nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
  color: var(--custom-hover) !important;
  opacity: 1;
}

span.navbar-text {
  display: flex;
  align-items: center;
}
.navbar-text a {
  color: white !important;
}
#icon-contact {
  margin-bottom: 2px;
  margin-left: 4px;
}

.social-icon {
  display: inline-block;
  margin-left: 14px;
}
.social-icon a {
  width: 42px;
  height: 42px;
  background: var(--custom-color);
  display: inline-flex;
  border-radius: 50%;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid rgba(255, 255, 255, 0.5);
}
.social-icon a::before {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  background-color: var(--custom-hover);
  border-radius: 50%;
  transform: scale(0);
	transition: 0.3s ease-in-out;
  border: 1px solid #fff;
}
.social-icon a:hover::before {
  transform: scale(1);
}
.social-icon a img {
	width: 40%;
	z-index: 1;
}
.navbar-text button {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 10px;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
	position: relative;
	background-color: var(--custom-color);
	transition: 0.3s ease-in-out;
}
.navbar-text button span {
  z-index: 1;
}
.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  border-radius: 10px;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.navbar-text button:hover {
  color: #2C3335;
}
.navbar-text button:hover::before {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
}
nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
	outline: none;
  box-shadow: none;
}
nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
	border-bottom: 2px solid #fff;
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
	width: 24px;
	position: absolute;
	height: 2px;
	background-color: #fff;
	top: 0;
	left: 0;
	content: '';
	z-index: 2;
	transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
	top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
	transform: rotate(45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
	transform: translateY(8px) rotate(-45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
	border-color: transparent;
}

/************ Home Css ************/
.home {
  margin-top: 0;
  padding: 114px 0 0px 0;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}

.home h1 {
  font-size: 65px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
  color: white;
}
.home .row {
  --bs-gutter-x: 0rem;
}

#atlas-copco{
  margin-top: 63px;
  width: 17%;
  float: right;
}

.distribuidor-autorizado{
  margin-top: 17px !important;
  text-align: center;
}
.atlas-copco-distribuidor{
  width: 185px;
}
.tetx-distribuidor{
  justify-content: center;
  color: #2596be;
}

/************ clients Css ************/
.client {
  margin-top: 67px;
  padding: 46px 0 0px 0;
  position: relative;
  transition: all ease 0.2s;
}
.client-bx {
  border-radius: 64px;
  text-align: center;
  padding: 60px 50px;
  margin-top: -60px;
}

.client h2 {
	font-size: 45px;
	font-weight: 700;
  color: var(--custom-color);
}
.client p {
  color: var(--custom-color);
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 20px 0 30px 0;
  text-align: left;
}
.client-slider {
  width: 80%;
  margin: 0 auto;
  position: relative;
}
.client-slider .item img {
  width: 50%;
  margin: 0 auto 15px auto;
}
.item h5 {
  color: var(--custom-color);
}
.background-image-left {
  top: 28%;
  position: absolute;
  bottom: 0;
  width: 40%;
  z-index: -4;
}
.react-multiple-carousel__arrow {
  background: var(--custom-color) !important;
}
.react-multiple-carousel__arrow:hover {
  background-color: var(--custom-hover) !important;
}
.react-multiple-carousel__arrow--right {
  right: calc(2% + 1px) !important;
}
.react-multiple-carousel__arrow--left {
  left: calc(2% + 1px) !important;
}

/************ Service Css ************/
.service {
  margin-top: 98px;
  padding: 20px 0 0px 0;
  position: relative;
  transition: all ease 0.2s;
}
.service h2 {
	font-size: 45px;
	font-weight: 700;
  color: var(--custom-color);
  margin-bottom: 40px;
}
.service p {
  color: var(--custom-color);
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 20px 0 30px 0;
  text-align: left;
}
.service .card {
  border-color: var(--custom-color);
}

.custom-card .card-img-top {
  height: 260px;
  width: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 4px;
}

.custom-card .card-body {
  height: 258px;
  overflow: hidden;
}


/************ ABOUT US Css ************/
.about {
  margin-top: 26px;
  padding: 93px 0 0px 0;
  position: relative;
  transition: all ease 0.2s;
}
.about-bx {
  text-align: center;
  margin-left: 13px;
  margin-right: 13px;
}
.about h2 {
	font-size: 45px;
	font-weight: 700;
  color: var(--custom-color);
}
.about p {
  color: var(--custom-color);
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  text-align: left;
}


/************ products Css ************/
.product {
  margin-top: 50px;
  padding: 47px 0 1px 0;
  position: relative;
}
.container-product{
  border-radius: 64px;
}
.row-product{
  padding: 37px;
}
.product h2 {
	font-size: 45px;
	font-weight: 700;
  text-align: center;
  color: var(--custom-color);
}
.product p {
  color: var(--custom-color);
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: left;
  width: 75%;
}
.product .nav.nav-pills {
  margin: 0 auto;
  border-radius: 50px;
  background-color: rgb(255 255 255 / 10%);
  overflow: hidden;
}
.product .nav.nav-pills .nav-item {
  width: 33.33333%;
}
.product .nav.nav-pills .nav-link {
  background-color: transparent;
  border-radius: 0;
  padding: 17px 0;
  color: var(--custom-color);
  width: 100%;
  font-size: 17px;
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
}
.product .nav.nav-pills .nav-link::before {
  content: "";
  position: absolute;
  width: 0;
  height: 100%;
  background: var(--custom-gradient);
  top: 0;
  left: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.product .nav.nav-pills .nav-link.active::before {
  width: 100% !important;
}
.product .nav.nav-pills .nav-link.active {
  border: 1px solid var(--custom-border);
  color: #fff !important;
}
.nav-link#products-tabs-tab-first {
  border: 1px solid var(--custom-border);
  border-radius: 55px 0px 0px 55px;
}
.nav-link#products-tabs-tab-second {
  border-top: 1px solid var(--custom-border);
  border-bottom: 1px solid var(--custom-border);
}
.nav-link#products-tabs-tab-third {
  border: 1px solid var(--custom-border);
  border-radius: 0 55px 55px 0;
}
.product-imgbx {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 24px;
}
.img-product{
  height: 297px;
}
.product-imgbx::before {
  content: "";
  background: var(--custom-gradient);
  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}
.product-imgbx:hover::before {
  height: 100%;
}
.product-txtx {
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
}
.product-imgbx:hover .product-txtx {
  top: 50%;
  opacity: 1;
}
.product-txtx h4 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}
.product-txtx span {
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.8px;
}
.background-image-right {
  top: 20%;
  position: absolute;
  bottom: 0;
  width: 35%;
  right: 0;
  z-index: -4;
}

/************ Contact Css ************/
.contact {
  margin-top: 68px;
  padding: 60px 0 40px 0;
}
.contact img {
  width: 89%;
}
.container-contact{
  border-radius: 64px;
}
.row-contact{
  padding: 0px 30px 0px 30px;
}
.contact h2 {
	font-size: 45px;
	font-weight: 700;
  margin-bottom: 30px;
  color: var(--custom-color);
}
.contact form input, .contact form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid var(--custom-border);
  border-radius: 20px;
  color: var(--custom-color);
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}
.contact form input:focus, .contact form textarea:focus {
  background:  var(--custom-gradient);
  color: #fff;
}
.contact form input::placeholder, .contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: var(--custom-color);
}
.contact form input:focus::placeholder, .contact form textarea:focus::placeholder {
  color: #fff;
  opacity: 0.8;
}
.contact form button {
  font-weight: 700;
  color: #fff;
  background-color: var(--custom-color);
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 7px;
  border-radius: 18px;
  position: relative;
  transition: 0.3s ease-in-out;
}
.contact form button span {
  z-index: 1;
  position: relative;
}
.contact form button:hover {
  color: #fff;
}
.contact form button::before {
  content: "";
  background: #9bbdc5;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
  border-radius: 18px;
}
.contact form button:hover::before {
  width: 100%;
}

/************ Footer Css ************/
.footer {
  background-color: var(--custom-color);
  padding: 15px 0 0px 0;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.footer img {  
  width: 47%;
}
.footer p {
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.5px;
  margin-bottom: 0px !important;
}
.copyright {
  color: #B8B8B8;
}
.info-contact{
  margin-bottom: 5px;
}

.slides-img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain !important;
}

.carousel-item {
  width: 100%;
  display: 'flex';
  justify-content: center;
  align-items: center;
}

.carousel-inner{
  height: 527px;
  border-radius:10px;
}

.carousel-caption h5 {
  color: #fff;
  width: 40%;
  text-transform: uppercase;
  font-size: 25px;
  font-weight: 700;
}
.carousel-caption p {
  color: #fff;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 20px 0 30px 0;
  text-align: left;
  width: 40%;
  text-transform: uppercase;
  font-weight: 700;
}
.carousel-control-prev-icon {
  color: #fff;
  background-color: var(--custom-color) !important;
  position: absolute;
  outline: 0;
  transition: all .5s;
  border-radius: 35px;
  z-index: 1000;
  border: 0;
  min-width: 43px;
  min-height: 43px;
  opacity: 1;
}
.carousel-control-next-icon {
  color: #fff;
  background-color: var(--custom-color) !important;
  position: absolute;
  outline: 0;
  transition: all .5s;
  border-radius: 35px;
  z-index: 1000;
  border: 0;
  min-width: 43px;
  min-height: 43px;
  opacity: 1;
}
.slider {
  margin:0 8px;
  height: 490px;
}

.slider img {
  max-width: 100%;
  max-height: 100%;
  border-radius:10px;
}

.react-multi-carousel-list {
  padding: 0rem 0 2rem 0;
}

.custom-dot-list-style button{
  border-color: var(--custom-color);
  background: var(--custom-color);
}
.react-multi-carousel-dot.react-multi-carousel-dot--active button{
  background: var(--custom-color) !important;
}
.react-multi-carousel-dot button {
  width: 12px;
  height: 12px;
  border-color: var(--custom-color) !important;
 
}
